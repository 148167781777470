<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">{{isUpdate ? 'Edit' : 'Add a new'}} registration</h2>
		<error-list :errors="page.saveErrors"></error-list>
		<success-alert ref="savedAlert" text="Changes saved." />
		<success-alert ref="emailAlert" text="Invoice email sent." />

		<b-form @submit.prevent="save">
			<b-form-group :invalid-feedback="requiredFeedback($v.form.conferenceId)">
				<b-form-select v-model="$v.form.conferenceId.$model" :options="options.conferences" :state="getValidState($v.form.conferenceId)">
					<template #first>
						<b-form-select-option :value="null" disabled>-- Select a conference --</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>

			<h3>Billing</h3>

			<b-row>
				<b-col lg>
					<b-form-group label="Billing Name" :invalid-feedback="requiredFeedback($v.form.billingName)">
						<b-form-input v-model.trim="$v.form.billingName.$model" type="text" :state="getValidState($v.form.billingName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-form-group label="Billing Email" :invalid-feedback="requiredFeedback($v.form.billingEmail)">
						<b-form-input v-model.trim="$v.form.billingEmail.$model" type="email" :state="getValidState($v.form.billingEmail)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col lg>
					<b-form-group label="Fees Paid" :invalid-feedback="requiredFeedback($v.form.feesPaid)">
						<b-form-input v-model.trim="$v.form.feesPaid.$model" type="number" step="any" :state="getValidState($v.form.feesPaid)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-row>
						<b-col lg="8">
							<b-form-group label="Fees Due" :invalid-feedback="requiredFeedback($v.form.feesDue)">
								<b-form-input v-model.trim="$v.form.feesDue.$model" type="number" step="any" :state="getValidState($v.form.feesDue)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg>
							<b-form-group label="Currency" :invalid-feedback="requiredFeedback($v.form.feesDueCurrency)">
								<b-form-input v-model.trim="$v.form.feesDueCurrency.$model" type="text" :state="getValidState($v.form.feesDueCurrency)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row>
				<b-col lg>
					<b-form-group label="Order Number" :invalid-feedback="requiredFeedback($v.form.orderNumber)">
						<b-form-input v-model.trim="$v.form.orderNumber.$model" type="text" :state="getValidState($v.form.orderNumber)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Registration Date" :invalid-feedback="requiredFeedback($v.form.registrationDate)">
						<b-form-input type="datetime-local" v-model.trim="$v.form.registrationDate.$model" :state="getValidState($v.form.registrationDate)" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<h3>Attendee</h3>

			<b-row>
				<b-col lg>
					<b-form-group label="Attendee Name" :invalid-feedback="requiredFeedback($v.form.attendeeName)">
						<b-form-input v-model.trim="$v.form.attendeeName.$model" type="text" :state="getValidState($v.form.attendeeName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-form-group label="Attendee Email" :invalid-feedback="requiredFeedback($v.form.attendeeEmail)">
						<b-form-input v-model.trim="$v.form.attendeeEmail.$model" type="email" :state="getValidState($v.form.attendeeEmail)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col lg>
					<b-form-group label="Attendee Organization" :invalid-feedback="requiredFeedback($v.form.attendeeOrganization)">
						<b-form-input v-model.trim="$v.form.attendeeOrganization.$model" type="text" :state="getValidState($v.form.attendeeOrganization)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col lg>
					<b-form-group label="Attendee Country" :invalid-feedback="requiredFeedback($v.form.attendeeCountry)">
						<b-form-input v-model.trim="$v.form.attendeeCountry.$model" type="text" :state="getValidState($v.form.attendeeCountry)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Attendee Needs/Requests" :invalid-feedback="requiredFeedback($v.form.attendeeNeeds)">
				<b-form-textarea v-model.trim="$v.form.attendeeNeeds.$model" :state="getValidState($v.form.attendeeNeeds)" rows="3"></b-form-textarea>
			</b-form-group>

			<b-form-group label="Additional Information" :invalid-feedback="requiredFeedback($v.form.additionalInformation)">
				<b-form-textarea v-model.trim="$v.form.additionalInformation.$model" :state="getValidState($v.form.additionalInformation)" rows="5"></b-form-textarea>
			</b-form-group>

			<b-table-simple hover small>
				<b-tbody>
					<b-tr>
						<b-td><b-checkbox v-model.trim="$v.form.attendingConference.$model">Attending Conference</b-checkbox></b-td>
						<b-td><b-checkbox v-model.trim="$v.form.attendingConferenceAsStudent.$model">Student</b-checkbox></b-td>
						<b-td><b-checkbox v-model.trim="$v.form.attendingConferenceOnline.$model">Online</b-checkbox></b-td>
						<b-td></b-td>
					</b-tr>
					<b-tr>
						<b-td><b-checkbox v-model.trim="$v.form.isPresenter.$model">Presenter</b-checkbox></b-td>
						<b-td><b-checkbox v-model.trim="$v.form.attendingDinnerGala.$model">Dinner Gala</b-checkbox></b-td>
						<b-td colspan="2">
							<b-form-input placeholder="As a guest of..." v-model.trim="$v.form.attendingDinnerGalaAsGuestOf.$model" type="text" :state="getValidState($v.form.attendingDinnerGalaAsGuestOf)"></b-form-input>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td><b-checkbox v-model.trim="$v.form.attendingWorkshop.$model">Attending Workshop</b-checkbox></b-td>
						<b-td><b-checkbox v-model.trim="$v.form.attendingWorkshopAsStudent.$model">Student</b-checkbox></b-td>
						<b-td><b-checkbox v-model.trim="$v.form.attendingWorkshopOnline.$model">Online</b-checkbox></b-td>
						<b-td>
							<b-form-input placeholder="Workshop name" v-model.trim="$v.form.workshopName.$model" type="text" :state="getValidState($v.form.workshopName)"></b-form-input>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<div v-for="(k, i) in Object.keys(form.additionalFields)" :key="i" class="my-3">
				<b-input-group :prepend="k">
					<b-form-input v-model="form.additionalFields[k]" type="text"></b-form-input>
					<b-input-group-append>
						<b-button variant="danger" @click="removeAdditionalField(k)">Remove</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>

			<div class="my-3">
				<b-input-group prepend="Add New Key/Value">
					<b-form-input v-model="options.additionalFieldKey" type="text"></b-form-input>
					<b-form-input v-model="options.additionalFieldValue" type="text"></b-form-input>
					<b-input-group-append>
						<b-button variant="primary" @click="addAdditionalField">Add</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" />
				<back-button class="btn btn-secondary mr-2" />
				<save-button :saving="page.saving" @click.native="sendEmail" class="mr-2" text="Send Invoice Email" variant="info" />

				<a v-if="isUpdate" :href="`/data/receipt/${confUrl}?order=${orderUrl}`" class="btn btn-info mr-2" target="_blank">
					Get Receipt
					<font-awesome-icon :icon="['fas', 'file-pdf']" fixed-width class="mr-2" />
				</a>

				<b-button v-if="isUpdate" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>
		</b-form>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this topic?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</auth-container>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'RegistrationForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				form: {
					conferenceId: null,
					registrationDate: null,
					orderNumber: null,
					billingName: null,
					billingEmail: null,
					feesPaid: 0,
					feesDue: 0,
					feesDueCurrency: null,
					attendeeName: null,
					attendeeEmail: null,
					attendeeOrganization: null,
					attendeeCountry: null,
					attendeeNeeds: null,
					attendingConference: false,
					attendingConferenceAsStudent: false,
					attendingConferenceOnline: false,
					attendingWorkshop: false,
					attendingWorkshopAsStudent: false,
					attendingWorkshopOnline: false,
					workshopName: null,
					attendingDinnerGala: false,
					attendingDinnerGalaAsGuestOf: null,
					additionalInformation: null,
					isPresenter: false,
					additionalFields: {}
				},
				options: {
					conferences: [],
					additionalFieldKey: '',
					additionalFieldValue: ''
				},
				confUrl: null,
				orderUrl: null
			}
		},
		validations: {
			form: {
				conferenceId: { required },
				registrationDate: { required },
				orderNumber: {},
				billingName: {},
				billingEmail: {},
				feesPaid: { required },
				feesDue: { required },
				feesDueCurrency: {},
				attendeeName: { required },
				attendeeEmail: { required },
				attendeeOrganization: {},
				attendeeCountry: {},
				attendeeNeeds: {},
				attendingConference: {},
				attendingConferenceAsStudent: {},
				attendingConferenceOnline: {},
				attendingWorkshop: {},
				attendingWorkshopAsStudent: {},
				attendingWorkshopOnline: {},
				workshopName: {},
				attendingDinnerGala: {},
				attendingDinnerGalaAsGuestOf: {},
				additionalInformation: {},
				isPresenter: {},
				additionalFields: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				try {
					const response = await this.$http.get(`invitations/conferences`, this.getTokenHeader());
					this.log(response.data);
					this.options.conferences = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				if (this.isUpdate) {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get(`registrations/${this.id}`, this.getTokenHeader());
						this.log(response.data);
						this.form = response.data.registration;
						this.form.registrationDate = this.dateTimeForForm(this.form.registrationDate);
						this.confUrl = response.data.conference;
						this.orderUrl = this.form.orderNumber.replace('#', '');

						if (this.form.additionalFields === null) {
							this.form.additionalFields = {};
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.log(this.form);

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							await this.$http.post('registrations', this.form, this.getTokenHeader());
							this.$router.push({ name: 'RegistrationList' }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`registrations/${this.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`registrations/${this.id}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'RegistrationList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async sendEmail() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					await this.$http.post(`registrations/sendemail/${this.id}`, {}, this.getTokenHeader());
					this.$refs.emailAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			addAdditionalField() {
				let keys = Object.keys(this.form.additionalFields);
				if (!this.isNullOrEmpty(this.options.additionalFieldKey) && !this.isNullOrEmpty(this.options.additionalFieldValue) && !(this.options.additionalFieldKey in keys)) {
					this.form.additionalFields[this.options.additionalFieldKey] = this.options.additionalFieldValue;
					this.options.additionalFieldKey = '';
					this.options.additionalFieldValue = '';
				}
			},
			removeAdditionalField(key) {
				if (key in this.form.additionalFields) {
					delete this.form.additionalFields[key];
				}
			}
		}
	}
</script>