<template>
	<div>
		<auth-container v-if="$route.name === 'RegistrationList'" :page="page" require-admin>
			<h2 class="mb-4">Registrations for {{stats.name}}</h2>

			<b-row>
				<b-col lg>
					<b-table-simple hover small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th></b-th>
								<b-th>Total</b-th>
								<b-th>Student</b-th>
								<b-th>Online</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-th>Conference</b-th>
								<b-td>{{stats.totals.conference}}</b-td>
								<b-td>{{stats.totals.conferenceStudents}}</b-td>
								<b-td>{{stats.totals.conferenceOnline}}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Presenters</b-th>
								<b-td>{{stats.totals.presenters}}</b-td>
								<b-td></b-td>
								<b-td></b-td>
							</b-tr>
							<b-tr>
								<b-th>Dinner Gala</b-th>
								<b-td>{{stats.totals.dinnerGala}}</b-td>
								<b-td></b-td>
								<b-td></b-td>
							</b-tr>
							<b-tr>
								<b-th>Workshop</b-th>
								<b-td>{{stats.totals.workshop}}</b-td>
								<b-td>{{stats.totals.workshopStudents}}</b-td>
								<b-td>{{stats.totals.workshopOnline}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
				<b-col lg>
					<b-table-simple hover small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th>Workshop</b-th>
								<b-th>Total</b-th>
								<b-th>Online</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="(w, i) in stats.totals.workshopsByName" :key="i">
								<b-td>{{w.name}}</b-td>
								<b-td>{{w.total}}</b-td>
								<b-td>{{w.online}}</b-td>
							</b-tr>
							<b-tr v-for="(w, j) in stats.totals.additionalFields" :key="j">
								<b-td>{{w.name}}</b-td>
								<b-td>{{w.total}}</b-td>
								<b-td>{{w.online}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
			</b-row>
			
			<p class="mb-4">
				<a :href="`/data/registrations/${confId}?access_token=${localStorageToken}`" class="btn btn-info mr-2">
					Download Registrations
					<font-awesome-icon :icon="['fas', 'file-excel']" fixed-width class="ml-1" />
				</a>
			</p>

			<grid-view ref="regTable" :api-url="`registrations/all/${confId}`" delete-api-url="registrations" use-filter
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="registrations" show-create
					   item-name="registration"></grid-view>

			<hr class="my-4" />
			<h3 class="my-4">Upload registrations</h3>

			<p>
				Upload a csv of registrations from the TAMU Flywire system. Make sure columns match the registration parser defined for the conference.
			</p>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'RegistrationList',
		components: {
			GridView, vueDropzone: vue2Dropzone
		},
		data() {
			return {
				confId: this.$route.params.conf,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: []
				},
				dropzoneOptions: {
					url: `/api/registrations/upload/${this.$route.params.conf}`,
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.csv'
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'registrationDate', label: 'Registered', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'attendeeName', label: 'Name', sortable: true },
						{ key: 'attendeeEmail', label: 'Email', sortable: true },
						{ key: 'attendingConference', label: 'Conf', sortable: true, formatter: (value) => { return value ? 'Y' : 'N' } },
						{ key: 'attendingConferenceOnline', label: 'Online', sortable: true, formatter: (value) => { return value ? '*' : '' } },
						{ key: 'attendingWorkshop', label: 'Workshop', sortable: true, formatter: (value) => { return value ? 'Y' : 'N' } },
						{ key: 'attendingWorkshopOnline', label: 'Online', sortable: true, formatter: (value) => { return value ? '*' : '' } },
						{ key: 'workshopName', sortable: true },
						{ key: 'attendingDinnerGala', label: 'Dinner', sortable: true, formatter: (value) => { return value ? 'Y' : 'N' } },
						{ key: 'feesPaid', label: 'Paid', sortable: true, formatter: (value) => { return '$' + this.numberWithCommas(value) } },
						{ key: 'attendeeCountry', label: 'Country', sortable: true },
						{ key: 'attendeeOrganization', label: 'Org', sortable: true },
						{ key: 'delete', label: '' }
					],
					sort: 'registrationDate',
					reverse: true,
					itemsPerPage: 50
				},
				stats: {
					name: null,
					totals: {
						conference: 0,
						conferenceStudents: 0,
						conferenceOnline: 0,
						workshop: 0,
						workshopStudents: 0,
						workshopOnline: 0,
						dinnerGala: 0,
						workshopsByName: [],
						presenters: 0
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.confId = this.$route.params.conf;

				try {
					const response = await this.$http.get(`registrations/stats/${this.confId}`, this.getTokenHeader());
					this.log(response.data);
					this.stats = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors = [];
				await this.$refs.regTable.get();
				await this.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>